import { toast } from "react-hot-toast";
import axios from "axios";
import * as actions from "../Actions/actions";
import { apiUrl } from "../Shared/shared";

export const LoginUser = (payload, navigate) => async (dispatch) => {
  console.log("Hello1");

  // Initialize authentication process
  dispatch(actions.init_auth());

  // Display a loading toast


  try {
    // Perform the login request
    const res = await axios.post(`${apiUrl}/login`, payload);
    if(res?.data?.data){
      
      dispatch(actions.login_success(res?.data?.data));

    
    }

  

    // Navigate to home page on successful login
    navigate("/");

  } catch (err) {
    console.log(err);

    // Dispatch failure action if an error occurs
    if (err.response) {
      dispatch(actions.login_failed(err?.response?.data?.message));

      // Update the loading toast to show error message
      toast.error(err?.response.data.message || "Login failed!");
    } else {
      // If there's no response (e.g., network error)
      toast.error("Login failed! Please try again.", );
    }
  } finally {

    console.log("Hello4");
  }
};
