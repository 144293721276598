import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import logo from '../../Assests/logo.png'
import Basiclogo from "../../Assests/basiclogo.png"

const settings = ['Profile', 'Dashboard', 'Logout'];

function BasicInformation() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };



  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" style={{
      backgroundColor:"#eddddd",
      padding:"10px 10px"
    }}>
      <Container>
        <Toolbar disableGutters sx={{
            display:"flex",
            justifyContent:"space-between"
        }}>
          
          
             <img style={{
              width:'100px',
              height:"40px"
             }} className='basi_logo' src={logo}/>
          
          <Box sx={{ 
            flexGrow: 0,
            display:"flex"
            }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
               <img style={{
                 width: "40px",
                 height: "40px",
                
               }} src={Basiclogo}/>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Box sx={{
                marginLeft:"10px"
            }}>
                <Typography sx={{
                    fontSize:"13px",
                    fontWeight:"500",
                    color:"#273B4A"
                }}>Anna Adame</Typography>
                <Typography sx={{
                    fontSize:"12px",
                    fontWeight:"400",
                    color:"#273B4A"
                }}>Hotel Area Delhir</Typography>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default BasicInformation;