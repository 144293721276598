import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import toast from "react-hot-toast";
import loginImg from "../../Assests/login.png";
import logo from "../../Assests/logo.png";
import { useDispatch } from "react-redux";
import savePhoneNumber from "../../API/saveNumber";
// Validation schema using Yup
const schema = yup.object().shape({
  emailOrNumber: yup
    .string()
    .required("Email/Phone number is required")
    .test(
      "emailOrNumber",
      "Please enter a valid email or phone number",
      (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/;
        return emailRegex.test(value) || phoneRegex.test(value);
      }
    ),
});

const Forgot = () => {
  // Initialize the form using react-hook-form and yup for validation
  const navigate= useNavigate()
  const dispatch=useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://api.welrm.com/api/forgot-password",
        { mobile: data.emailOrNumber,
          type:"mobile"
         }
      );

      if (response.data.success) {
        const userToken = response.data.data.userToken;
        if (userToken) {
          // Store the userToken in localStorage
          dispatch(savePhoneNumber(data.emailOrNumber))
          localStorage.setItem("userToken", userToken);
          toast.success("OTP sent successfully!");
          navigate("/forget-otp")
        } else {
          toast.error("User token is missing.");
        }
      } else {
        toast.error(response.data.message || "Something went wrong!");
      }
    } catch (error) {
      toast.error("Error sending the reset instructions.");
    }
  };

  return (
   <Box sx={{
    backgroundColor:"#F1F1F1"
   }}>
   
   <Box sx={{
            padding: "20px"
         }}>
            <Link to="/login"> Back</Link>

         </Box>
     <Box
    
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      bgcolor: "#F1F1F1",
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "50px",
        flex: "1",
        margin: "30px 0",
      }}
    >
      <Box>
        <img src={logo} width="190.63px" alt="logo" />
      </Box>
      <Box
        sx={{
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "10px",
          maxWidth: "631px",
          width: "80%",
        }}
      >
        <Typography
          sx={{
            fontSize: "26px",
            fontWeight: "600",
            textTransform: "capitalize",
            color: "#383737",
            textAlign: "center",
            marginBottom: "15px",
          }}
        >
          Forgot Your password?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            textTransform: "capitalize",
            color: "#6B7280",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          No worries! Enter your registered email address or phone number
          below and we'll send you instructions to reset your password.
        </Typography>

        {/* Form */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography
            sx={{
              alignSelf: "flex-start",
              marginBottom: "8px",
              fontWeight: "500",
            }}
          >
            Email/Number
          </Typography>
          <TextField
            {...register("emailOrNumber")}
            placeholder="welrm123@gmail.com or 1234567890"
            variant="outlined"
            fullWidth
            error={!!errors.emailOrNumber}
            helperText={errors.emailOrNumber?.message}
            sx={{ marginBottom: "16px" }}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                marginTop: "20px",
                padding: "3px 10px",
              }}
            >
              <Typography>
                Remember Password?
                <Link
                  to="/login"
                  style={{
                    textDecoration: "none",
                    color: "#C42A25",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  Login
                </Link>
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                sx={{
                  color: "white",
                  textAlign: "center",
                  bgcolor: "#C42A25",
                  borderRadius: "10px",
                  padding: "10px 50px",
                }}
              >
                Send Code
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
    <Box
      sx={{
        maxWidth: "35.3%",
        display: { xs: "none", sm: "flex" },
        justifyContent: "end",
        height: "100vh",
      }}
    >
      <img
        style={{
          width: "90%",
          height: "100vh",
          objectFit: "cover",
        }}
        src={loginImg}
        alt="Login"
      />
    </Box>
  </Box>
   </Box>
  );
};

export default Forgot;
