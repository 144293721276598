import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  MenuItem,
  Grid,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import basicbg from "../../Assests/basicbg.png"; // Ensure this path is correct
import location from "../../Assests/location.png";
import picture from "../../Assests/picture.png";
import SideImg from "../../Assests/side.png";
import toast from "react-hot-toast";
import HotelData from "../../API/Hotel";
import { useDispatch } from "react-redux";

const propertyTypes = ["Hotel", "Guest House", "Resort", "Motel"];

// Validation schema using Yup
const schema = yup.object().shape({
  hotelName: yup.string().required("Hotel Name is required"),
  fullName: yup.string().required("Owner Name is required"),
  propertyType: yup.string().required("Property Type is required"),
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  stdCode: yup
    .string()
    .required("STD Code is required")
    .matches(/^\d{2,10}$/, "STD Code must be between 2 to 10 digits"),
  landlineNumber: yup
    .string()
    .required("Landline Number is required")
    .matches(/^\d{10}$/, "Landline Number must be exactly 10 digits"),
});

const BasicInfo = ({ setFrom }) => {
  const [images, setImages] = useState([]);
const dispatch=useDispatch()
  // React Hook Form setup
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Handle image uploads
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0] && images.length < 3) {
      setImages([...images, URL.createObjectURL(e.target.files[0])]);
    }
  };

  // Remove selected image
  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  // Handle form submission
  const onSubmit = async (data) => {
    // Ensure that images is properly defined if you intend to send it
    const images = data.images || []; // Adjust this depending on your form input for images
  
    // Prepare the payload for submission
    const payload = {
      hotelName: data.hotelName,
      fullName: data.fullName,
      propertyType: data.propertyType,
      email: data.email,
      stdCode: data.stdCode,
      landlineNumber: data.landlineNumber,
      images, // Send images if necessary
    };
  
    try {
      // Retrieve token from localStorage
      const token = localStorage.getItem("token"); 
      if (!token) {
        toast.error("Authentication token not found. Please log in again.");
        return;
      }
  
      // Set the request headers with the OAuth token
      const config = {
        headers: {
          "oauth-token": token, // Send the token with the oauth-token key in headers
          "Content-Type": "application/json", // Ensure correct content-type header for JSON data
        },
      };
  
      // Send the form data to the API
      const response = await axios.post('https://api.welrm.com/api/hotel/', payload, config); // Replace 'API_ENDPOINT' with actual URL
      HotelData()
      dispatch(HotelData(response.data))
      // Log the API response
      console.log("Success:", response.data);
  
      // Show success toast notification
      toast.success("Hotel information submitted successfully!");
  
      // Navigate to the next form step if successful
      setFrom(2);
    } catch (error) {
      // Log error details for debugging
      console.error("Error:", error.response ? error.response.data : error.message);
  
      // Show error toast notification
      toast.error("Submission failed. Please try again.");
    }
  };
  

  return (
    <div>
      <Box
        sx={{
          padding: "20px  80px",
          backgroundColor: "#F1F1F1",
          position: "relative",
        }}>
        <img
          src={SideImg}
          alt="side"
          style={{
            width: "47px",
            position: "absolute",
            top: "240px",
            left: "15px",
          }}
        />
        <Box
          sx={{
            padding: { xs: "20px", md: "40px" },
            borderRadius: "10px",
            backgroundColor: "#ffffff",
          }}>
          <Box
            sx={{
              backgroundImage: `url(${basicbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            <Typography
              sx={{
                fontSize: { xs: "24px", md: "34px" },
                fontWeight: 700,
                color: "#FFFFFF",
              }}>
              Discover WELRM
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "16px",
                  md: "24px",
                  margin: "0px 20px",
                  textAlign: "center",
                },
                fontWeight: 400,
                color: "#FFFFFF",
              }}>
              Join WELRM and enhance your hotel's visibility. Let's get your
              property ready for bookings!
            </Typography>
          </Box>

          {/* Basic Information Section */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ p: 3 }}>
              <Typography sx={{ fontSize: "22px", fontWeight: 600, color: "#000" }}>
                Basic Information
              </Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: 400, color: "#4B5563", mb: 2 }}>
                Let's Get Started with Your Hotel's Basic Information
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1 }}>
                    Hotel Name
                  </Typography>
                  <Controller
                    name="hotelName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        error={!!errors.hotelName}
                        helperText={errors.hotelName?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1 }}>
                    Owner Name
                  </Typography>
                  <Controller
                    name="fullName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        error={!!errors.fullName}
                        helperText={errors.fullName?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1 }}>
                    Select Property Type
                  </Typography>
                  <Controller
                    name="propertyType"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        select
                        variant="outlined"
                        error={!!errors.propertyType}
                        helperText={errors.propertyType?.message}
                        {...field}>
                        {propertyTypes.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1 }}>
                    Email
                  </Typography>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        type="email"
                        variant="outlined"
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* STD Code, Landline Number, and Hotel Location */}
            <Box sx={{ p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1 }}>
                    STD Code *
                  </Typography>
                  <Controller
                    name="stdCode"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Type 2 to 4 digits code"
                        error={!!errors.stdCode}
                        helperText={errors.stdCode?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1 }}>
                    Landline Number *
                  </Typography>
                  <Controller
                    name="landlineNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Type landline number"
                        error={!!errors.landlineNumber}
                        helperText={errors.landlineNumber?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1 }}>
                    Hotel Location
                  </Typography>
                  <Button
                    fullWidth
                    startIcon={<img src={location} alt="location" />}
                    sx={{ p: 1, backgroundColor: "#F5F5F5", color: "#000" }}>
                    Select on Map
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* Hotel Image Upload Section */}
            <Box sx={{ p: 3 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: 600, mb: 1 }}>
                Add Photos of Your Property (Max 3 Photos)
              </Typography>
              <Box sx={{
                display:"flex",
                justifyContent:"space-between"
              }}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",

                  }}>
                  {images.map((image, index) => (
                    <Box
                      key={index}
                      sx={{
                        position: "relative",
                        width: "100px",
                        height: "100px",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}>
                      <img
                        src={image}
                        alt={`Preview ${index}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <Button
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          padding: "2px",
                          minWidth: "unset",
                          bgcolor: "rgba(255,255,255,0.5)",
                        }}
                        onClick={() => handleRemoveImage(index)}>
                        X
                      </Button>
                    </Box>
                  ))}
                  {images.length < 3 && (
                    <Button
                      component="label"
                      variant="outlined"
                      sx={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <img
                        style={{ width: "24px", height: "24px" }}
                        src={picture}
                        alt="Add Icon"
                      />
                      <input
                        type="file"
                        hidden
                        onChange={handleImageChange}
                        accept="image/*"
                      />
                    </Button>
                  )}
                </Box>
                <Box
                  sx={{
                    p: 3,
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#D0D5DD",
                      color: "#101828",
                      borderRadius: "8px",
                      padding: "12px 20px",
                      marginRight:"40px",
                    }}
                    onClick={() => setFrom(0)}>
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      bgcolor: "#7F56D9",
                      color: "#ffffff",
                      borderRadius: "8px",
                      padding: "12px 20px",
                    }}>
                    Save and Continue
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default BasicInfo;
