import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
} from "@mui/material";

import BasicInformation from "./BasicInformationNav";

const amenitiesData = {
  essential: [
    "WiFi",
    "Car Parking",
    "24-Hour Reception",
    "Daily Housekeeping",
    "Air Conditioning",
    "Non-Smoking Rooms",
    "Heating",
    "Toiletries",
    "Hair Dryer",
    "Free Wi-Fi",
    "Parking",
    "24-Hour Reception",
    "24-Hour Reception",
  ],
  comfort: [
    "Restaurant",
    "Bar",
    "Room Service",
    "Laundry Service",
    "Concierge",
    "Business Center",
    "Airport Shuttle",
    "Pet-Friendly",
    "Swimming Pool",
    "Business Center",
    "Air Conditioning",
    "Airport Shuttle",
    "Bar/Lounge",
  ],
  luxury: [
    "Business Center",
    "Restaurant",
    "Air Conditioning",
    "Spa",
    "Laundry Service",
    "Airport Shuttle",
    "Bar/Lounge",
    "Swimming Pool",
  ],
  additional: [
    "Business Center",
    "Restaurant",
    "Air Conditioning",
    "Spa",
    "Laundry Service",
    "Airport Shuttle",
    "Bar/Lounge",
    "Swimming Pool",
  ],
};

const ChooseAmenities = ({ setFrom }) => {
  const [selectedAmenities, setSelectedAmenities] = useState([]);

  const handleCheckboxChange = (amenity) => {
    setSelectedAmenities((prevState) =>
      prevState.includes(amenity)
        ? prevState.filter((item) => item !== amenity)
        : [...prevState, amenity]
    );
  };

  const renderCheckboxes = (amenities, category) => (
    <Grid item xs={12} md={12}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {category}
      </Typography>
      {amenities.map((amenity, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={selectedAmenities.includes(amenity)}
              onChange={() => handleCheckboxChange(amenity)}
            />
          }
          label={amenity}
        />
      ))}
    </Grid>
  );

  return (
    <>
      <BasicInformation />
      <Box
        sx={{
          padding: "20px 80px",
          backgroundColor: "#F1F1F1",
          position: "relative",
        }}>
        <Box
          sx={{
            padding: { xs: "20px", md: "40px" },
            borderRadius: "10px",
            backgroundColor: "#ffffff",
          }}>
          <Box sx={{ p: 3 }}>
            <Typography
              sx={{ fontSize: "22px", fontWeight: 600, color: "#000", mb: 2 }}>
              Choose the amenities your hotel offers to enhance guest experience
            </Typography>
            <Grid container spacing={2}>
              {/* Essential Amenities */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}>
                <Box
                  sx={{
                    padding: "20px",
                  }}>
                  {renderCheckboxes(
                    amenitiesData.essential,
                    "Essential Amenities"
                  )}
                </Box>

                {/* Comfort Amenities */}
                <Box
                  sx={{
                    padding: "20px",
                  }}>
                  {renderCheckboxes(amenitiesData.comfort, "Comfort Amenities")}
                </Box>

                {/* Luxury Amenities */}
                <Box
                  sx={{
                    padding: "20px",
                  }}>
                  {renderCheckboxes(amenitiesData.luxury, "Luxury Amenities")}
                </Box>

                {/* Additional Services */}
                <Box
                  sx={{
                    padding: "20px",
                  }}>
                  {renderCheckboxes(
                    amenitiesData.additional,
                    "Additional Services"
                  )}
                </Box>
              </Box>
            </Grid>

            {/* Action Buttons */}
            <Box
              sx={{
                display: "flex",
                flexwrap: "wrap",
                justifyContent: "flex-end",
                gap: 2,
                mt: 4,
              }}>
              <Button
                onClick={() => setFrom(2)}
                sx={{
                  fontSize: "18px",
                  fontWeight: 500,
                  color: "#475569",
                  bgcolor: "#F1F5F9",
                  borderRadius: "10px",
                  padding: "10px 40px",
                }}>
                Back
              </Button>
              <Button
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  bgcolor: "#C42A25",
                  borderRadius: "10px",
                  padding: "10px 40px",
                }}>
                {selectedAmenities.length} Add Amenities
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ChooseAmenities;
