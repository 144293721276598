import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form"; // For form validation
import axios from "axios"; // For API request
import { Link, useNavigate } from "react-router-dom"; // Navigation after successful password reset
import { toast } from "react-hot-toast"; // For notifications
import loginImg from "../../Assests/login.png";
import logo from "../../Assests/logo.png";

const YourNewPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate(); // For navigation after successful reset

  // Form setup with react-hook-form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Watch password field for matching validation
  const password = watch("password");

  const onSubmit = async (data) => {
    const { password, confirmPassword } = data;
  
    // Validate password and confirmPassword
    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }
  
    try {
      const token = localStorage.getItem("token");
  
      if (!token) {
        console.log("No token, authorization denied.");
        return;
      }
  
      const config = {
        headers: {
          "oauth-token": token, // Ensure this is the correct header key for your API
        },
      };
  
      const response = await axios.put(
        "https://api.welrm.com/api/user/reset-password",
        {
          newPassword: password,
          confirmPassword: confirmPassword, // Explicitly send confirmPassword
        },
        config
      );
  
      if (response.data.success) {
        toast.success("Password reset successfully!");
        navigate("/login"); // Redirect to login page after success
      } else {
        toast.error(response.data.message || "Password reset failed.");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred while resetting the password.");
    }
  };
  

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        bgcolor: "#F1F1F1",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "50px",
          flex: "1",
          margin: "30px 0",
        }}
      >
        <Box>
          <img src={logo} width="190.63px" alt="logo" />
        </Box>
        <Box
          sx={{
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "10px",
            maxWidth: "631px",
            width: "80%",
          }}
        >
          <Typography
            sx={{
              fontSize: "26px",
              fontWeight: "600",
              textTransform: "capitalize",
              color: "#383737",
              textAlign: "center",
              marginBottom: "15px",
            }}
          >
            Set Your New Password
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              textTransform: "capitalize",
              color: "#6B7280",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Create a new password for your WELRM account. Ensure it is secure and memorable.
          </Typography>

          {/* Password Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              sx={{
                alignSelf: "flex-start",
                marginBottom: "8px",
                fontWeight: "500",
              }}
            >
              Password
            </Typography>
            <TextField
              placeholder="India@12345"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              sx={{ marginBottom: "16px" }}
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters long",
                },
                pattern: {
                  value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
                  message:
                    "Password must include at least one letter, one number, and one special character",
                },
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.password && (
              <Typography color="error" variant="caption">
                {errors.password.message}
              </Typography>
            )}

            <Typography
              sx={{
                alignSelf: "flex-start",
                marginBottom: "8px",
                fontWeight: "500",
              }}
            >
              Confirm Password
            </Typography>
            <TextField
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              sx={{ marginBottom: "16px" }}
              {...register("confirmPassword", {
                required: "Please confirm your password",
                validate: (value) =>
                  value === password || "Passwords do not match",
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.confirmPassword && (
              <Typography color="error" variant="caption">
                {errors.confirmPassword.message}
              </Typography>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  marginTop: "20px",
                  padding: "3px 10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <input type="checkbox" />
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Password must meet our security criteria.
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  marginTop: "20px",
                }}
              >
                <Button
                  type="submit"
                  sx={{
                    color: "white",
                    textAlign: "center",
                    bgcolor: "#C42A25",
                    borderRadius: "10px",
                    textAlign: "center",
                    padding: "10px 50px",
                  }}
                >
                  Set & Continue
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: "35.3%",
          display: { xs: "none", sm: "flex" },
          justifyContent: "end",
          height: "100vh",
        }}
      >
        <img
          style={{
            width: "90%",
            height: "100vh",
            objectFit: "cover",
          }}
          src={loginImg}
          alt="Login"
        />
      </Box>
    </Box>
  );
};

export default YourNewPassword;
