import React, { useState } from 'react';
import { TextField,  Input, MenuItem,  IconButton, Box, Typography, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel } from '@mui/material';



import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Images
import picture from "../../Assests/picture.png";
import anities from "../../Assests/anities.png";

const propertyTypes = ['Hotel', 'Guest House', 'Resort', 'Motel'];
const discount = ['30', '60', '90', '120']

function AddNewRoom() {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const [availableRooms, setAvailableRooms] = useState(0);
  const [bookedRooms, setBookedRooms] = useState(0);
  const [images, setImages] = useState([]);



  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0] && images.length < 3) {
      setImages([...images, URL.createObjectURL(e.target.files[0])]);
    }
  };


  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };


  const handleAvailableIncrement = () => setAvailableRooms(availableRooms + 1);
  const handleAvailableDecrement = () => availableRooms > 0 && setAvailableRooms(availableRooms - 1);


  const handleBookedIncrement = () => setBookedRooms(bookedRooms + 5);
  const handleBookedDecrement = () => bookedRooms > 0 && setBookedRooms(bookedRooms - 5);



  return (
    <Box sx={{ padding: "10px 10px", backgroundColor: "#F1F1F1", position: "relative" }}>

      <Box sx={{ padding: { xs: "20px", md: "40px" }, borderRadius: "10px", backgroundColor: "#ffffff" }}>

        <Box sx={{ p: 3 }}>
          <Typography sx={{ fontSize: "22px", fontWeight: 600, color: "#000" }}>
            Add Room Details & Availability
          </Typography>
          <Typography sx={{ fontSize: "16px", fontWeight: 400, color: "#4B5563", mb: 2 }}>
            Provide Information About Your Rooms
          </Typography>
          <Typography sx={{ fontSize: "22px", fontWeight: "600", mt: "50px" }}>
            Room Details:
          </Typography>

          <Grid container spacing={2}>

            <Grid item xs={12} sm={12} md={5}>
              <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1, marginTop: "20px" }}>
                Room Name *
              </Typography>
              <TextField fullWidth required variant="outlined" />
            </Grid>

            <Grid item xs={12} sm={12} md={7}>
              <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500", marginTop: "20px" }} gutterBottom>
                      No. Of Available Rooms *
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        border: "1px solid #94A3B880",
                        padding: "7px 40px",
                        borderRadius: "10px"
                      }}
                    >
                      <IconButton
                        onClick={handleAvailableDecrement}
                        sx={{ color: '#9CA3AF' }}
                        aria-label="decrement"
                      >
                        <RemoveIcon />
                      </IconButton>

                      <Typography variant="h6">{availableRooms}</Typography>

                      <IconButton
                        onClick={handleAvailableIncrement}
                        sx={{ color: '#9CA3AF' }}
                        aria-label="increment"
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500", marginTop: "20px" }} gutterBottom>
                      No. Of Guest Capacity *
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        border: "1px solid #94A3B880",
                        padding: "7px 40px",
                        borderRadius: "10px"
                      }}
                    >
                      <IconButton
                        onClick={handleBookedDecrement}
                        sx={{ color: '#9CA3AF' }}
                        aria-label="decrement"
                      >
                        <RemoveIcon />
                      </IconButton>

                      <Typography variant="h6">{bookedRooms}</Typography>

                      <IconButton
                        onClick={handleBookedIncrement}
                        sx={{ color: '#9CA3AF' }}
                        aria-label="increment"
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {/* Room Description */}
            <Grid item xs={12} sm={12} md={5}>
              <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1, marginTop: "20px" }}>
                Room Description *
              </Typography>
              <TextField
                label="Write room description"
                fullWidth required variant="outlined"
              />
              {/* Amenities Box */}
              <Box sx={{
                display: "flex",
                justifyContent: "center",
                bgcolor: "#F1F5F9",
                alignItems: "center",
                padding: "10px 40px",
                border: "1px solid #CBD5E1",
                borderRadius: "10px",
                mb: { xs: 2, md: 0 },
                marginTop: "40px"
              }}>
                <Box>
                  <img style={{
                    width: "34px",
                    height: "34px",
                    marginRight: "20px"
                  }} src={anities} />
                </Box>
                <Box onClick={handleClickOpen}>
                  <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>Include Amenities</Typography>
                </Box>
                <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                  <DialogTitle sx={{ fontSize: '24px', fontWeight: 600 }}>Add Hotel Amenities (19)</DialogTitle>
                  <DialogContent>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Comfort And Convenience</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Air Conditioning" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Heating" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Room Service" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Daily Housekeeping" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Iron/Ironing Board" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Air Conditioning" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Safe" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Heating" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Mini-Bar" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Daily Housekeeping" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Coffee/Tea Maker" />
                      </Grid>
                      <Grid item xs={3}>
                       <Box sx={{
                        bgcolor:"#E2E8F0",
                        borderRadius:"10px",
                        padding:"10px 10px",
                        width:"40px"
                       }}>
                          <Typography>+ 10</Typography>
                       </Box>
                      </Grid>

                      {/* Add more amenities here similar to the image */}
                    </Grid>

                    <Typography variant="h6" sx={{ fontWeight: 600, mt: 3 }}>Entertainment</Typography>
                    <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Flat-screen TV" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Cable/Satellite Channels" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="In-Room Movies" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Wi-Fi" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Bluetooth Speakers" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="DVD Player" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Air Conditioning" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Heating" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Room Service" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Daily Housekeeping" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Iron/Ironing Board" />
                      </Grid>
                      <Grid item xs={3}>
                       <Box sx={{
                        bgcolor:"#E2E8F0",
                        borderRadius:"10px",
                        padding:"10px 10px",
                        width:"40px"
                       }}>
                          <Typography>+ 10</Typography>
                       </Box>
                      </Grid>
                      {/* Add more entertainment amenities here */}
                    </Grid>

                    <Typography variant="h6" sx={{ fontWeight: 600, mt: 3 }}>Bathroom</Typography>
                    <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Private Bathroom" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Shower" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Hair Dryer" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Wi-Fi" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Bluetooth Speakers" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="DVD Player" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Bathtub" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Room Service" />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel control={<Checkbox />} label="Hair Dryer" />
                      </Grid>
                      <Grid item xs={3}>
                       <Box sx={{
                        bgcolor:"#E2E8F0",
                        borderRadius:"10px",
                        padding:"10px 10px",
                        width:"40px"
                       }}>
                          <Typography>+ 10</Typography>
                       </Box>
                      </Grid> 

                      {/* Add more bathroom amenities here */}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} sx={{ color: '#9CA3AF', fontWeight: '500' }}>Cancel</Button>
                    <Button onClick={handleClose} variant="contained" sx={{ bgcolor: '#C42A25', color: '#FFFFFF' }}>
                      Add Amenities
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Grid>

            {/* Basic Room Price */}
            <Grid item xs={12} sm={12} md={7}>
              <Box>
                <Typography sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginTop: "20px",
                  marginLeft: "10px"
                }}>Basic Room Price *</Typography>

                <Box sx={{
                  bgcolor: "#F8FAFC",
                  border: "1px solid #E2E8F0",
                  borderRadius: "10px",
                  padding: "10px"
                }}>
                  {/* Basic Price */}
                  <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', sm: 'row' },
                    mb: 2
                  }}>
                    <Typography sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      mb: { xs: 1, sm: 0 }
                    }}>Basic Price</Typography>
                    <Box className="my-input">
                      <TextField
                        sx={{ padding: "4px 10px !important" }}
                        placeholder="₹ Add Rate"
                        fullWidth
                      />
                    </Box>
                  </Box>

                  {/* Discount */}
                  <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', sm: 'row' },
                    mb: 2
                  }}>
                    <Typography sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      mb: { xs: 1, sm: 0 }
                    }}>Discount</Typography>
                    <Box className="my-input">
                      <TextField
                        select
                        defaultValue={30}
                        fullWidth required variant="outlined"
                      >
                        {discount.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Box>

                  {/* After Discount Price */}
                  <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', sm: 'row' }
                  }}>
                    <Typography sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      mb: { xs: 1, sm: 0 }
                    }}>After Discount Price</Typography>
                    <Box className="my-input">
                      <TextField
                        sx={{ padding: "4px 10px !important" }}
                        placeholder="₹ Add Rate"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box >
            <Typography sx={{
              fontSize: "22px",
              fontWeight: "600"
            }}>Availability :</Typography>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#6B7280"
            }}>Please select the start & end dates </Typography>
          </Box>
          <Box sx={{
            // bgcolor: "#CBD5E1",
            display: "fex",
            width: "100%",


          }}>
            <Grid container spacing={2} sx={{

            }}>
              {/* Start and End Date */}
              <Grid item xs={12} sm={12} md={6}>
                <Box sx={{
                  display: "flex",
                  // flexWrap:"wrap",
                  width: "100%",
                  justifyContent: "space-between",
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 2,
                  marginTop: "20px"
                }}>
                  <Box>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>Start Date *</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>End Date *</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Grid>

              {/* Cancellation Policy */}
              <Grid item xs={12} sm={12} md={6}>
                <Box>
                  <Typography sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    marginBottom: "20px"
                  }}>Cancellation Policy :</Typography>

                  <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 2,
                  }}>

                    <Box sx={{
                      border: "1px solid #C42A25",
                      borderRadius: "10px",
                      padding: "10px",
                      flex: 1
                    }}>
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                      }}>
                        <Box className="refund_input"><TextField type='checkbox' /></Box>
                        <Typography sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          marginLeft: "10px"
                        }}>Full Refund 1 Day Prior</Typography>
                      </Box>
                      <Typography sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#9CA3AF",
                        marginLeft: "30px",
                        marginTop: "5px"
                      }}>Cancel up to 1 day (24 hours) before check-in for a full refund. Later cancellations incur a 100% penalty.</Typography>
                    </Box>

                    {/* Second Policy Box */}
                    <Box sx={{
                      border: "1px solid #C42A25",
                      borderRadius: "10px",
                      padding: "10px",
                      flex: 1
                    }}>
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                      }}>
                        <Box className="refund2_input"><TextField type='checkbox' /></Box>
                        <Typography sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          marginLeft: "10px"
                        }}>3-Day Refund Before Check-In</Typography>
                      </Box>
                      <Typography sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#9CA3AF",
                        marginLeft: "30px",
                        marginTop: "5px"
                      }}>Cancel up to 3 days (72 hours) before check-in for a full refund. 100% penalty for late cancellations.</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

        </Box>

        {/* Image Upload Section */}
        <Box sx={{ p: { xs: 2, md: 3 }, justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: { xs: "18px", md: "22px" }, fontWeight: 600, color: "#000" }}>
            Add Hotel Images:
          </Typography>
          <Typography sx={{ fontSize: { xs: "14px", md: "16px" }, fontWeight: 400, color: "#4B5563", mb: 2 }}>
            The building's exterior, parking space(s), entrance, & any available facilities
          </Typography>

          <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 2, md: 5 }
          }}>

            <Box>
              <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", gap: 2, flexWrap: "wrap" }}>
                {images.map((img, index) => (
                  <Box key={index} sx={{ position: "relative", display: "inline-block" }}>
                    <img src={img} alt={`Uploaded Preview ${index}`} style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                    <Button
                      size="small"
                      sx={{ position: "absolute", top: 0, right: 0, bgcolor: "red", color: "#fff" }}
                      onClick={() => handleRemoveImage(index)}
                    >
                      X
                    </Button>
                  </Box>
                ))}

                {/* Add Image Button */}
                {images.length < 3 && (
                  <label htmlFor="upload-photo">
                    <Input
                      id="upload-photo"
                      type="file"
                      onChange={handleImageChange}
                      sx={{ display: 'none' }}
                    />
                    <Box
                      sx={{
                        width: { xs: "80px", md: "100px" },
                        height: { xs: "80px", md: "100px" },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "2px dashed #ccc",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <img src={picture} alt="Upload Icon" style={{ width: "50px", height: "50px" }} />
                    </Box>
                  </label>
                )}
              </Box>
            </Box>

            {/* Save and Back Buttons Section */}
            <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-end" }, width: "100%", flexDirection: "column", gap: 5 }}>

              <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" }, gap: 2 }}>
                <Button
                  sx={{
                    fontSize: { xs: "18px", md: "24px" },
                    fontWeight: "600",
                    color: "#475569",
                    bgcolor: "#4755691A",
                    border: "1px solid #0000001A",
                    borderRadius: "10px",
                    padding: { xs: "5px 20px", md: "10px 50px" },
                    mr: 2,
                  }}
                >
                  Back
                </Button>
                <Button
                  sx={{
                    fontSize: { xs: "18px", md: "24px" },
                    fontWeight: "600",
                    color: "#FFFFFF",
                    bgcolor: "#C42A25",
                    borderRadius: "10px",
                    padding: { xs: "5px 20px", md: "10px 50px" },
                  }}
                >
                  Save & Continue
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

      </Box>
    </Box>
  );
}

export default AddNewRoom;
