import React, { useContext, useState } from "react";
import { TabContext } from "./TabContex"; // Adjust the import path as needed
import "./Navbar.scss";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import Menu from '@mui/material/Menu'; // Import Menu from MUI
import MenuItem from '@mui/material/MenuItem'; // Import MenuItem from MUI
import Typography from '@mui/material/Typography'; // Import Typography from MUI

import profile from '../../../Assests/basiclogo.png'


const settings = ['Profile', 'Dashboard', 'Logout'];

const Navbar = () => {
  const { activeTab } = useContext(TabContext);
  const [notificationCount, setNotificationCount] = useState(5); // Dynamic notification count
  const [anchorElUser, setAnchorElUser] = useState(null); // State for menu anchor

  // Handle opening the user menu
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // Handle closing the user menu
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <h1>{activeTab}</h1> {/* Display the active tab */}
        </div>
        
        <div className="items">
          {/* Settings Icon */}
          <div
            className="item"
            style={{
              backgroundColor: "#FFF",
              height: "30px",
              width: "30px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              borderRadius: "40px",
            }}
          >
              <SettingsOutlinedIcon className="icon" />
          </div>  
          <div
            className="item"
            style={{
              backgroundColor: "#FFF",
              height: "30px",
              width: "30px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              borderRadius: "40px",
            }}
          >
            <Badge badgeContent={notificationCount} color="primary">
              <NotificationsIcon className="icon" />
            </Badge>
          </div>  
          <div
            className="item"
            style={{
              backgroundColor: "#FFF",
              height: "30px",
              width: "30px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              borderRadius: "40px",
            }}
            onClick={handleOpenUserMenu} // Open menu on click
          >
            <img src={profile} style={{
              width:"20px",
              height:"20px"
            }}/>
          </div>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)} // Open menu if anchorElUser is set
            onClose={handleCloseUserMenu} // Close menu when clicked outside
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
