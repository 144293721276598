import React from "react";
import { Box, Typography, Button } from "@mui/material";

import basicbg from "../../Assests/basicbg.png"; // Ensure this path is correct

import Side3 from "../../Assests/side3.png";

import BasicInformationNav from "./BasicInformationNav";

function SubscriptionDetails({ setFrom }) {
  return (
    <>
      <BasicInformationNav />

      <Box
        sx={{
          padding: "20px  80px",
          backgroundColor: "#F1F1F1",
          position: "relative",
        }}>
        <img
          src={Side3}
          alt="side"
          style={{
            width: "47px",
            position: "absolute",
            top: "240px",
            left: "15px",
          }}
        />
        <Box
          sx={{
            padding: { xs: "20px", md: "40px" },
            borderRadius: "10px",
            backgroundColor: "#ffffff",
          }}>
          <Box
            sx={{
              backgroundImage: `url(${basicbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            <Typography
              sx={{
                fontSize: { xs: "24px", md: "34px" },
                fontWeight: 700,
                color: "#FFFFFF",
              }}>
              Discover WELRM
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "16px",
                  md: "24px",
                  margin: "0px 20px",
                  textAlign: "center",
                },
                fontWeight: 400,
                color: "#FFFFFF",
              }}>
              Join WELRM and enhance your hotel's visibility. Let's get your
              property ready for bookings!
            </Typography>
          </Box>

          {/* Basic Information Section */}
          <Box sx={{ p: 3 }}>
            <Typography
              sx={{ fontSize: "22px", fontWeight: 600, color: "#000" }}>
              Basic Information
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#4B5563",
                mb: 2,
              }}>
              Let's Get Started with Your Hotel's Basic Information
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <Typography
                sx={{
                  fontSize: "26px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}>
                Enjoy Premium Benefits and Enhanced Visibility for Just ₹300 per
                Month
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  textAlign: "center",
                  maxWidth: "1200px",
                  marginBottom: "20px",
                }}>
                Unlock the full potential of your hotel with our premium
                subscription plan. Enhance your property’s visibility, attract
                more guests, and maximize your revenue. Experience exclusive
                benefits designed to give you a competitive edge in the market
              </Typography>

              <Box
                sx={{
                  bgcolor: "#F1F5F9",
                  borderRadius: "20px",
                  padding: "20px 50px",
                  // display:"flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "40px",
                    borderBottom: "2px solid #CBD5E1",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                      }}>
                      {" "}
                      Payment Status
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#C42A25",
                      }}>
                      Pending....
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "40px",
                    color: "#64748B",
                  }}>
                  <Typography>
                    Complete your payment to unlock premium features and
                    maximize your hotel's visibility.
                  </Typography>
                  <Button
                    onClick={() => setFrom(4)}
                    sx={{
                      fontSize: { xs: "18px", md: "24px" },
                      fontWeight: "600",
                      color: "#FFFFFF",
                      bgcolor: "#C42A25",
                      borderRadius: "10px",
                      marginTop: "40px",
                      padding: { xs: "5px 20px", md: "10px 50px" },
                    }}>
                    PAY NOW ₹300
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "40px",
                  justifyContent: { xs: "end", md: "flex-end  " },
                  gap: 2,
                }}>
                <Button
                  sx={{
                    fontSize: { xs: "18px", md: "24px" },
                    fontWeight: "600",
                    color: "#475569",
                    bgcolor: "#4755691A",
                    border: "1px solid #0000001A",
                    borderRadius: "10px",
                    padding: { xs: "5px 20px", md: "10px 50px" },
                    mr: 2,
                  }}>
                  Back
                </Button>
                <Button
                  sx={{
                    fontSize: { xs: "18px", md: "24px" },
                    fontWeight: "600",
                    color: "#FFFFFF",
                    bgcolor: "#C42A25",
                    borderRadius: "10px",
                    padding: { xs: "5px 20px", md: "10px 50px" },
                  }}>
                  Save & Continue
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SubscriptionDetails;
