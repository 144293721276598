import React, { useState, useEffect } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Box, Typography, TextField, Button, Input } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assests/logo.png";
import { useDispatch, useSelector } from "react-redux";
import loginImg from "../../Assests/login.png";
import axios from "axios";
import toast from "react-hot-toast";
import { apiUrl } from "../../Shared/shared";

const VerifyOtp = () => {
  const [otp, setOtp] = useState(""); // OTP is a string
  const [timeLeft, setTimeLeft] = useState(120);
  const navigate = useNavigate()

  const mobileNumber = useSelector((state) => state?.SaveNumber?.mobile); // Get mobile number from Redux
  const userToken = localStorage.getItem("userToken"); // Get userToken from localStorage
  console.log(mobileNumber);
  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timerId); // Cleanup on unmount
    }
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleChange = (newValue) => {
    setOtp(newValue); // newValue is already an array
  };
  const handleVerifyOtp = async () => {
    if (otp.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP.");
      return;
    }

    try {
      const response = await axios.post("https://api.welrm.com/api/forgot-password/otp-verify", {
        otp,
        mobile: mobileNumber,
        userToken: userToken
      });

      if (!response.data) {
        toast.success("OTP verified successfully!");
        // Redirect user to the next page (e.g., password reset)
        navigate("/YourNewPassword")
      } else {
        toast.error(response.data.message || "OTP verification failed.");
      }
    } catch (error) {
      toast.error("Error verifying OTP. Please try again.");
    }
  };
  const handleResendOtp = async () => {

    try {
      const response = await axios.post(`${apiUrl}/user/send-otp/owner`, {
        mobile: Number(mobileNumber),
        userType: "owner",
        countryCode: 91,
      });

      if (response.data.success === true) {
        toast.success("OTP resent successfully!");
        setTimeLeft(120); // Reset the timer
      }
    } catch (error) {
      toast.error("Error in resending OTP. Please try again.");
    }

    setTimeLeft(120); // Reset timer after resending OTP
    toast.success("OTP resent successfully!");
  };

  return (
    <Box sx={{
       backgroundColor:"#F1F1F1"
    }}>
       <Box sx={{
            padding: "20px"
         }}>
            <Link to="/ForgetPassword"> Back</Link>

         </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          bgcolor: "#F1F1F1",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "50px",
            flex: "1",
            margin: "30px 0",
          }}>
          <Box>
            <img src={logo} width="190.63px" alt="logo" />
          </Box>
          <Box
            sx={{
              padding: "20px",
              backgroundColor: "white",
              borderRadius: "10px",
              maxWidth: "631px",
              width: "80%",
            }}>
            <Typography
              sx={{
                fontSize: "26px",
                fontWeight: "600",
                textTransform: "capitalize",
                color: "#383737",
                textAlign: "center",
                marginBottom: "15px",
              }}>
              Verify Your OTP
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "capitalize",
                color: "#6B7280",
                textAlign: "center",
                marginBottom: "20px",
              }}>
              Enter the OTP (One-Time Password) sent to your registered email or
              phone number to verify your account. Complete this step to secure
              access to your WELRM account.
            </Typography>
            <div>
              <MuiOtpInput value={otp} onChange={handleChange} length={6} />
            </div>

            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                width: "100%",
                marginTop: "20px",
              }}>
              <h1
                style={{
                  marginRight: "10px",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign: "left",
                  marginTop: "11px",
                }}>
                Resend OTP in{" "}
              </h1>
              <h2
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                }}>
                {formatTime(timeLeft)}
              </h2>
              {timeLeft === 0 && <p>Time's up!</p>}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "20px",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Typography
                  sx={{
                    marginTop: "15px",
                    fontSize: "12px",
                  }}>
                  Didn't Received code?
                  <a

                    onClick={handleResendOtp}
                    style={{
                      textDecoration: "none",
                      color: "#C42A25",
                      fontWeight: "500",
                      cursor: "pointer"
                    }}>

                    Resend
                  </a>
                </Typography>
              </Box>

              <Button
                onClick={handleVerifyOtp}
                sx={{
                  color: "white",
                  textAlign: "center",
                  bgcolor: "#C42A25",
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "10px 50px",
                }}>
                Verify
              </Button>

            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}>
              <Box
                sx={{
                  marginTop: "20px",
                }}></Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: "35.3%",
            display: { xs: "none", sm: "flex" }, // Hide on xs (mobile) and show from sm (650px) and up
            justifyContent: "end",
            height: "100vh", // Ensure the box takes the full height of the viewport
          }}>
          <img
            style={{
              width: "90%",
              height: "100vh", // Set the image height to 100% of the viewport height
              objectFit: "cover", // Maintain the aspect ratio and cover the area
            }}
            src={loginImg}
            alt="Login"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default VerifyOtp;
