import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import loginImg from "../../Assests/login.png";
import logo from "../../Assests/logo.png";
import toast from "react-hot-toast";
import axios from "axios";
import { apiUrl } from "../../Shared/shared";

// Yup validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must include at least one uppercase letter")
    .matches(/[a-z]/, "Password must include at least one lowercase letter")
    .matches(/[0-9]/, "Password must include at least one digit")
    .matches(
      /[!@#$%^&*]/,
      "Password must include at least one special character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const SetYourPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema), // Connect Yup schema to react-hook-form
  });

  const onSubmit = async (data) => {
    try {
      const token = localStorage.getItem("token"); // Retrieve token from localStorage
      if (!token) {
        toast.error("Authentication token not found. Please log in again.");
        return;
      }
  
      const config = {
        headers: {
          "oauth-token": token, // Send the token with the oauth-token key in headers
        },
      };
  
      // Make the POST request and pass 'data' and 'config'
      const response = await axios.post(`${apiUrl}/user/reset-password`, data, config);
      localStorage.setItem('users', response.data.data); 
      console.log(response);
      if (response.data.success === true) {
        toast.success("Password updated successfully");
        navigate("/Dashboard"); // Navigate to the Dashboard on success
      } else {
        toast.error("Invalid password!");
      }
    } catch (error) {
      toast.error("Password update failed.");
      console.log(error);
    }
    
    console.log("Form Submitted Successfully:", data);
  };
  

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        bgcolor: "#F1F1F1",
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "50px",
          flex: "1",
          margin: "30px 0",
        }}>
        <Box>
          <img src={logo} width="190.63px" alt="logo" />
        </Box>
        <Box
          sx={{
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "10px",
            maxWidth: "631px",
            width: "80%",
          }}>
          <Typography
            sx={{
              fontSize: "26px",
              fontWeight: "600",
              textTransform: "capitalize",
              color: "#383737",
              textAlign: "center",
              marginBottom: "15px",
            }}>
            Set Your New Password
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              textTransform: "capitalize",
              color: "#6B7280",
              textAlign: "center",
              marginBottom: "20px",
            }}>
            Create a new password for your WELRM account below. Ensure it's
            secure and memorable. Welcome back to seamless management with
            WELRM!
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              sx={{
                alignSelf: "flex-start",
                marginBottom: "8px",
                fontWeight: "500",
              }}>
              Password
            </Typography>
            <TextField
              placeholder="India@12345"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              {...register("password")} // Register the password input with react-hook-form
              error={!!errors.password}
              helperText={errors.password?.message}
              sx={{ marginBottom: "16px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Typography
              sx={{
                alignSelf: "flex-start",
                marginBottom: "8px",
                fontWeight: "500",
              }}>
              Confirm Password
            </Typography>
            <TextField
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              {...register("confirmPassword")} // Register the confirmPassword input with react-hook-form
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              sx={{ marginBottom: "16px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}>
              <Box
                sx={{
                  marginTop: "20px",
                  padding: "3px 10px",
                }}>
                <Box sx={{ display: "flex" }}>
                  <input type="checkbox" />
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}>
                    Password must meet our security criteria.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ marginTop: "20px" }}>
                <Button
                  type="submit"
                  sx={{
                    color: "white",
                    textAlign: "center",
                    bgcolor: "#C42A25",
                    borderRadius: "10px",
                    padding: "10px 50px",
                  }}>
                  Set & Continue
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: "35.3%",
          display: { xs: "none", sm: "flex" },
          justifyContent: "end",
          height: "100vh",
        }}>
        <img
          style={{
            width: "90%",
            height: "100vh",
            objectFit: "cover",
          }}
          src={loginImg}
          alt="Login"
        />
      </Box>
    </Box>
  );
};

export default SetYourPassword;
