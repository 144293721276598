import React, { useContext, useState } from "react";
import { TabContext } from "../Navbar/TabContex";
import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Dashlogo from "../../../Assests/Dashlogo.png";

const Sidebar = () => {
  const { setActiveTab } = useContext(TabContext); // Use the TabContext

  // Manage the state for all expandable sections
  const [isDashboardOpen, setDashboardOpen] = useState(false);
  const [isRoomOpen, setRoomOpen] = useState(false);
  const [isProductsOpen, setProductsOpen] = useState(false);
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [isSubscriptionOpen, setSubscriptionOpen] = useState(false);
  const [isSettingOpen, setSettingOpen] = useState(false);
  const [isSupportOpen, setSupportOpen] = useState(false);

  // Function to toggle sections
  const toggleSection = (section) => {
    setActiveTab(section); // Update the active tab in context

    if (section === "dashboard") setDashboardOpen(!isDashboardOpen);
    if (section === "Room") setRoomOpen(!isRoomOpen);
    if (section === "products") setProductsOpen(!isProductsOpen);
    if (section === "Notification") setNotificationOpen(!isNotificationOpen);
    if (section === "Subscription") setSubscriptionOpen(!isSubscriptionOpen);
    if (section === "Setting") setSettingOpen(!isSettingOpen);
    if (section === "Support") setSupportOpen(!isSupportOpen);
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">
            <img
              style={{ width: "130px", height: "40px" }}
              src={Dashlogo}
              alt="Dashboard Logo"
            />
          </span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul className="sidebar_main">
          <p className="title">Menu</p>

          {/* Dashboard Section */}
          <li onClick={() => toggleSection("dashboard")} style={{
          
          }}>
            <Link className="das_link" to="/dashboard/Chart" style={{ textDecoration: "none" }}>
              <DashboardIcon />
              <span className="Das">Dashboard</span>
            </Link >
            {isDashboardOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </li>
          {isDashboardOpen && (
            <ul className="sub-menu">
              <li>
                <Link to="/dashboard/Chart" style={{ textDecoration: "none" }}>
                  <span>Today</span>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/Chart" style={{ textDecoration: "none" }}>
                  <span>Yesterday</span>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/week" style={{ textDecoration: "none" }}>
                  <span>This Week</span>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/month" style={{ textDecoration: "none" }}>
                  <span>Last 4 Weeks</span>
                </Link>
              </li>
            </ul>
          )}

          {/* Users Section */}
          <li onClick={() => toggleSection("Room")}>
            <PersonOutlineIcon />
            <span>Hotel Profile</span>
            {isRoomOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </li>
          {isRoomOpen && (
            <ul className="sub-menu">
              <li>
                <Link
                  to="/dashboard/NewBooking"
                  style={{ textDecoration: "none" }}>
                  <span>New Booking Requests</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/ActiveBooking"
                  style={{ textDecoration: "none" }}>
                  <span>Active Bookings</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/BookingHistory"
                  style={{ textDecoration: "none" }}>
                  <span>Booking History</span>
                </Link>
              </li>
            </ul>
          )}

          {/* Products Section */}
          <li onClick={() => toggleSection("products")}>
            <StoreIcon />
            <span>Room Manage</span>
            {isProductsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </li>
          {isProductsOpen && (
            <ul className="sub-menu">
              <li>
                <Link
                  to="/dashboard/RoomListing"
                  style={{ textDecoration: "none" }}>
                  <span>Room Listings</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/AddNewRoom"
                  style={{ textDecoration: "none" }}>
                  <span>Add New Room</span>
                </Link>
              </li>
            </ul>
          )}

          {/* Notifications Section */}
          <li onClick={() => toggleSection("Notification")}>
            <NotificationsNoneIcon />
            <span>Notifications</span>
            {isNotificationOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </li>
          {isNotificationOpen && (
            <ul className="sub-menu">
              <li>
                <Link
                  to="/dashboard/BookingAlert"
                  style={{ textDecoration: "none" }}>
                  <span>Booking Alerts</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/GuestMessage"
                  style={{ textDecoration: "none" }}>
                  <span>Guest Messages</span>
                </Link>
              </li>
            </ul>
          )}

          {/* Subscription Section */}
          <li onClick={() => toggleSection("Subscription")}>
            <InsertChartIcon />
            <span>Subscription</span>
            {isSubscriptionOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </li>
          {isSubscriptionOpen && (
            <ul className="sub-menu">
              <li>
                <Link to="/dashboard/today" style={{ textDecoration: "none" }}>
                  <span>Today</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/yesterday"
                  style={{ textDecoration: "none" }}>
                  <span>Yesterday</span>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/week" style={{ textDecoration: "none" }}>
                  <span>This Week</span>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/month" style={{ textDecoration: "none" }}>
                  <span>Last 4 Weeks</span>
                </Link>
              </li>
            </ul>
          )}

          {/* Settings Section */}
          <li onClick={() => toggleSection("Setting")}>
            <SettingsApplicationsIcon />
            <span>Setting</span>
            {isSettingOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </li>
          {isSettingOpen && (
            <ul className="sub-menu">
              <li>
                <Link
                  to="/dashboard/Policies"
                  style={{ textDecoration: "none" }}>
                  <span>Policies</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/DeleteAccount"
                  style={{ textDecoration: "none" }}>
                  <span>Delete Account</span>
                </Link>
              </li>
            </ul>
          )}

          {/* Support Section */}
          <li onClick={() => toggleSection("Support")}>
            <PsychologyOutlinedIcon />
            <span>Support</span>
            {isSupportOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </li>
          {isSupportOpen && (
            <ul className="sub-menu">
              <li>
                <Link to="/dashboard/Faq" style={{ textDecoration: "none" }}>
                  <span>FAQs</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/Contact"
                  style={{ textDecoration: "none" }}>
                  <span>Contact Us</span>
                </Link>
              </li>
            </ul>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
