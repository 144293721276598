import React, { useState, useEffect } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Box, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "@mui/material";
import { apiUrl } from "../../Shared/shared";
import toast from "react-hot-toast";

import logo from "../../Assests/logo.png";
import loginImg from "../../Assests/login.png";
import { useNavigate } from "react-router-dom";

const VerifyOtpEmailPhone = () => {
  const [otp, setOtp] = useState(""); // Initialize OTP as an empty string
  const [timeLeft, setTimeLeft] = useState(120);
  const [error, setError] = useState(""); // State for validation error
  const mobileNumber = useSelector((state) => state?.SaveNumber?.mobile);

  const navigate = useNavigate();
  
  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId); // Cleanup the interval on component unmount
    }
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // OTP input change handler
  const handleChange = (newValue) => {
    setOtp(newValue); // Set OTP as a string
    if (error) setError(""); // Clear the error on input change
  };

  const handleVerify = async () => {
    if (otp.length !== 6 || isNaN(otp)) {
      setError("Please enter a valid 6-digit OTP.");
      return; // Stop the function if validation fails
    }
  
    try {
      const response = await axios.post(`${apiUrl}/user/verify-otp`, {
        otp: Number(otp), // Convert OTP to number
        mobile: Number(mobileNumber),
        countryCode: 91,
      });
      console.log(response);
  
      if (response.data.success === true) {
        const accessToken = response.data.data.accesstoken; // Access the token in response.data.data.accesstoken
        if (accessToken) {
          localStorage.setItem('token', accessToken); 
          toast.success("OTP verified successfully!");
          navigate("/Password");
        } else {
          toast.error("Access token not found.");                                      
        }
      } else {
        toast.error("Invalid OTP!");
      }
    } catch (error) {
      toast.error("Verification failed.");
      console.log(error);
    }
  };
  const handleResendOtp = async () => {
    try {
      const response = await axios.post(`${apiUrl}/user/send-otp/owner`, {
        mobile: Number(mobileNumber),
        userType: "owner",
        countryCode: 91,
      });

      if (response.data.success === true) {
        toast.success("OTP resent successfully!");
        setTimeLeft(120); // Reset the timer
      } else {
        toast.error("Failed to resend OTP.");
      }
    } catch (error) {
      toast.error("Error in resending OTP. Please try again.");
    }
  };
  return (
   <Box sx={{
     backgroundColor:"#F1F1F1"
   }}> 
 <Box sx={{
            padding: "20px"
         }}>
            <Link to="/register"> Back</Link>

         </Box>

<Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        bgcolor: "#F1F1F1",
      }}
    >
      {/* Left section with logo and form */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "50px",
          flex: "1",
          margin: "30px 0",
        }}
      >
        {/* Logo */}
        <Box>
          <img src={logo} width="190.63px" alt="logo" />
        </Box>

        {/* OTP Verification Form */}
        <Box
          sx={{
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "10px",
            maxWidth: "631px",
            width: "80%",
          }}
        >
          <Typography
            sx={{
              fontSize: "26px",
              fontWeight: "600",
              textTransform: "capitalize",
              color: "#383737",
              textAlign: "center",
              marginBottom: "15px",
            }}
          >
            Verify Your Email/Phone Number OTP
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              textTransform: "capitalize",
              color: "#6B7280",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Enter the verification code sent to your email or phone to complete
            your registration. Secure your WELRM account now.
          </Typography>

          {/* OTP Input */}
          <div>
            <MuiOtpInput value={otp} onChange={handleChange} length={6} />
          </div>

          {/* Error message for OTP validation */}
          {error && (
            <Typography
              sx={{
                color: "red",
                fontSize: "14px",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              {error}
            </Typography>
          )}

          {/* Resend OTP timer */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "left",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                marginRight: "10px",
                fontSize: "14px",
                fontWeight: "500",
                textAlign: "left",
                marginTop: "11px",
              }}
            >
              Resend OTP in{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                marginTop:"12px",
                fontWeight: "500",
              }}
            >
              {formatTime(timeLeft)}
            </Typography>
            {timeLeft === 0 && <Typography>Time's up!</Typography>}
          </Box>

          {/* Action buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                marginTop: "15px",
                fontSize: "12px",
              }}
            >
              Didn't receive the code?
              <a    onClick={handleResendOtp} style={{ textDecoration: "none", color: "#C42A25" }}>
                {" "}
                Resend
              </a>
            </Typography>

            {/* Confirm Button */}
            <Button
              onClick={handleVerify}
              sx={{
                color: "white",
                textAlign: "center",
                bgcolor: "#C42A25",
                borderRadius: "10px",
                padding: "10px 50px",
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Right section with image */}
      <Box
        sx={{
          maxWidth: "35.3%",
          display: { xs: "none", sm: "flex" }, // Hide on xs (mobile), show on sm and up
          justifyContent: "end",
          height: "100vh", // Full height for image section
        }}
      >
        <img
          style={{
            width: "90%",
            height: "100vh", // Image full height
            objectFit: "cover", // Maintain aspect ratio
          }}
          src={loginImg}
          alt="Login"
        />
      </Box>
    </Box>
   </Box>
  );
};

export default VerifyOtpEmailPhone;
