import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Input,
  MenuItem,
  Grid,
  IconButton,
} from "@mui/material";

import RemoveIcon from "@mui/icons-material/Remove";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";


import BasicInformation from "./BasicInformationNav";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";

// Images
import picture from "../../Assests/picture.png";
import anities from "../../Assests/anities.png";
import side2 from "../../Assests/side2.png";

const propertyTypes = ["Hotel", "Guest House", "Resort", "Motel"];
const discount = ["30", "60", "90", "120"];

const validationSchema = yup.object().shape({
  roomName: yup.string().required("Room Name is required"),
  roomDescription: yup.string().required("Room Description is required"),
  availableRooms: yup
    .number()
    .required("Available rooms are required")
    .min(1, "At least 1 room must be available"),
  guestCapacity: yup
    .number()
    .required("Guest capacity is required")
    .min(1, "Guest capacity must be at least 1"),
  roomPrice: yup
    .number()
    .required("Room price is required")
    .min(1, "Room price must be a positive value"),
  discount: yup.string().required("Discount is required"),
onDay:yup.boolean(),
Threeday:yup.boolean(),
  images: yup.array().of(yup.mixed()).max(3, "You can upload a maximum of 3 images"),
  startDate: yup.date().required("Start Date is required"),
  endDate: yup.date().required("End Date is required"),
});




function RoomDetails({ setFrom }) {

  const [images, setImages] = useState([]);
  const hotelId = useSelector((state) => state?.HotelData?.hotel?.id);
  const userId = JSON.parse(localStorage.getItem("users"))?.id;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });



  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0] && images.length < 3) {
      setImages([...images, URL.createObjectURL(e.target.files[0])]);
    }
  };

  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const onSubmit = (data) => {
    console.log("Form Data:", data,images);
    // setFrom(3);
  };



  return (
    <>
      <BasicInformation />
  
    <Box
      sx={{
        padding: "20px 80px",
        backgroundColor: "#F1F1F1",
        position: "relative",
      }}>
      <img
        src={side2}
        alt="side"
        style={{
          width: "47px",
          position: "absolute",
          top: "240px",
          left: "15px",
        }}
      />
  <form>
  
  <Box
        sx={{
          padding: { xs: "20px", md: "40px" },
          borderRadius: "10px",
          backgroundColor: "#ffffff",
        }}>
       
        <Box sx={{ p: 3 }}>
          <Typography sx={{ fontSize: "22px", fontWeight: 600, color: "#000" }}>
            Add Room Details & Availability
          </Typography>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 400, color: "#4B5563", mb: 2 }}>
            Provide Information About Your Rooms
          </Typography>
          
          <Typography sx={{ fontSize: "22px", fontWeight: "600", mt: "50px" }}>
            Room Details:
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  mb: 1,
                  marginTop: "20px",
                }}>
                Room Name *
              </Typography>
              <TextField fullWidth  variant="outlined"
                  {...register("roomName")}
                  error={!!errors.roomName}
                  helperText={errors.roomName?.message} />
            </Grid>

            <Grid item xs={12} sm={12} md={7}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        marginTop: "20px",
                      }}
                      gutterBottom>
                      No. Of Available Rooms *
                    </Typography>
                    <Controller
                      name="availableRooms"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            border: "1px solid #94A3B880",
                            padding: "7px 40px",
                            borderRadius: "10px",
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              field.onChange(
                                Math.max(0, parseInt(field.value || 0) - 1)
                              )
                            }
                            sx={{ color: "#9CA3AF" }}
                            aria-label="decrement"
                          >
                            <RemoveIcon />
                          </IconButton>

                          <Typography variant="h6">{field.value || 0}</Typography>

                          <IconButton
                            onClick={() =>
                              field.onChange(parseInt(field.value || 0) + 1)
                            }
                            sx={{ color: "#9CA3AF" }}
                            aria-label="increment"
                          >
                            <AddIcon />
                          </IconButton>
                        </Box>
                      )}
                    />
                    <Typography color="error" variant="body2">
                      {errors.availableRooms?.message}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        marginTop: "20px",
                      }}
                      gutterBottom>
                      No. Of Guest Capacity *
                    </Typography>
                    <Controller
                      name="guestCapacity"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            border: "1px solid #94A3B880",
                            padding: "7px 40px",
                            borderRadius: "10px",
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              field.onChange(
                                Math.max(0, parseInt(field.value || 0) - 1)
                              )
                            }
                            sx={{ color: "#9CA3AF" }}
                            aria-label="decrement"
                          >
                            <RemoveIcon />
                          </IconButton>

                          <Typography variant="h6">{field.value || 0}</Typography>

                          <IconButton
                            onClick={() =>
                              field.onChange(parseInt(field.value || 0) + 1)
                            }
                            sx={{ color: "#9CA3AF" }}
                            aria-label="increment"
                          >
                            <AddIcon />
                          </IconButton>
                        </Box>
                      )}
                    />
                    <Typography color="error" variant="body2">
                      {errors.guestCapacity?.message}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {/* Room Description */}
            <Grid item xs={12} sm={12} md={5}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  mb: 1,
                  marginTop: "20px",
                }}>
                Room Description *
              </Typography>
              <TextField
                label="Write room description"
                fullWidth
                {...register("roomDescription")}
                error={!!errors.roomDescription}
                helperText={errors.roomDescription?.message}

                variant="outlined"
              />
            </Grid>

            {/* Basic Room Price */}
            <Grid item xs={12} sm={12} md={7}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    marginTop: "20px",
                    marginLeft: "10px",
                  }}>
                  Basic Room Price *
                </Typography>

                <Box
                  sx={{
                    bgcolor: "#F8FAFC",
                    border: "1px solid #E2E8F0",
                    borderRadius: "10px",
                    padding: "10px",
                  }}>
                  {/* Basic Price */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: { xs: "column", sm: "row" },
                      mb: 2,
                    }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        mb: { xs: 1, sm: 0 },
                      }}>
                      Basic Price
                    </Typography>
                    <Box className="my-input">
                      <TextField
                        sx={{ padding: "4px 10px !important" }}
                        placeholder="₹ Add Rate"
                        {...register("roomPrice")}
                        error={!!errors.roomPrice}
                        helperText={errors.roomPrice?.message}
                        fullWidth
                      />
                    </Box>
                  </Box>

                  {/* Discount */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: { xs: "column", sm: "row" },
                      mb: 2,
                    }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        mb: { xs: 1, sm: 0 },
                      }}>
                      Discount
                    </Typography>
                    <Box className="my-input">
                      <TextField
                        select
                        defaultValue={30}
                        fullWidth
                        {...register("discount")}
                        error={!!errors.discount}
                        helperText={errors.discount?.message}
                        variant="outlined">
                        {discount.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Box>

                  {/* After Discount Price */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: { xs: "column", sm: "row" },
                    }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        mb: { xs: 1, sm: 0 },
                      }}>
                      After Discount Price
                    </Typography>
                    <Box className="my-input">
                      <TextField
                        sx={{ padding: "4px 10px !important" }}
                        placeholder="₹ Add Rate"
                        fullWidth
                        {...register("discount")}
                        error={!!errors.discount}
                        helperText={errors.discount?.message}
                        
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "600",
              }}>
              Availability :
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#6B7280",
              }}>
              Please select the start & end dates{" "}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {/* Start and End Date */}
            <Grid item xs={12} sm={12} md={5}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 2,
                  marginTop: "20px",
                }}>
                <Box>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                    Start Date * <br/>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                    <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    )}
                  />
                  <br/>
                  <Typography color="error" variant="body2">
                   <br/> {errors.startDate?.message}
                  </Typography>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                    End Date *
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                    <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    )}
                  />
                  <Typography color="error" variant="body2">
                    {errors.endDate?.message}
                  </Typography>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>
            </Grid>

            {/* Cancellation Policy */}
            <Grid item xs={12} sm={12} md={7}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    marginBottom: "20px",
                  }}>
                  Cancellation Policy :
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: 2,
                  }}>
                  <Box
                    sx={{
                      border: "1px solid #C42A25",
                      borderRadius: "10px",
                      padding: "10px",
                      flex: 1,
                    }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <Box className="refund_input">
                        <TextField type="checkbox"    {...register("oneDay")} />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          marginLeft: "10px",
                        }}>
                        Full Refund 1 Day Prior
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#9CA3AF",
                        marginLeft: "30px",
                        marginTop: "5px",
                      }}>
                      Cancel up to 1 day (24 hours) before check-in for a full
                      refund. Later cancellations incur a 100% penalty.
                    </Typography>
                  </Box>

                  {/* Second Policy Box */}
                  <Box
                    sx={{
                      border: "1px solid #C42A25",
                      borderRadius: "10px",
                      padding: "10px",
                      flex: 1,
                    }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <Box className="refund2_input">
                        <TextField type="checkbox" {...register("ThreeDay")} />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          marginLeft: "10px",
                        }}>
                        3-Day Refund Before Check-In
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#9CA3AF",
                        marginLeft: "30px",
                        marginTop: "5px",
                      }}>
                      Cancel up to 3 days (72 hours) before check-in for a full
                      refund. 100% penalty for late cancellations.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p: { xs: 2, md: 3 }, justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: { xs: "18px", md: "22px" },
              fontWeight: 600,
              color: "#000",
            }}>
            Add Hotel Images:
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: 400,
              color: "#4B5563",
              mb: 2,
            }}>
            The building's exterior, parking space(s), entrance, & any available
            facilities
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 2, md: 5 },
            }}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  gap: 2,
                  flexWrap: "wrap",
                }}>
                {images.map((img, index) => (
                  <Box
                    key={index}
                    sx={{ position: "relative", display: "inline-block" }}>
                    <img
                      src={img}
                      alt={`Uploaded Preview ${index}`}
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                      }}
                    />
                    <Button
                      size="small"
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bgcolor: "red",
                        color: "#fff",
                      }}
                      onClick={() => handleRemoveImage(index)}>
                      X
                    </Button>
                  </Box>
                ))}

                {/* Add Image Button */}
                {images.length < 3 && (
                  <label htmlFor="upload-photo">
                    <Input
                      id="upload-photo"
                       accept="image/*"
                      type="file"
                      onChange={handleImageChange}
                      sx={{ display: "none" }}
                    />
                    <Box
                      sx={{
                        width: { xs: "80px", md: "100px" },
                        height: { xs: "80px", md: "100px" },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "2px dashed #ccc",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}>
                      <img
                        src={picture}
                        alt="Upload Icon"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </Box>
                  </label>
                )}
              </Box>
            </Box>

            {/* Save and Back Buttons Section */}
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-end" },
                width: "100%",
                flexDirection: "column",
                gap: 5,
              }}>
              {/* Amenities Box */}
              <Button
                onClick={() => setFrom(5)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  bgcolor: "#F1F5F9",
                  alignItems: "center",
                  padding: "10px 40px",
                  color: "#000",
                  border: "1px solid #CBD5E1",
                  borderRadius: "10px",
                  mb: { xs: 2, md: 0 },
                }}>
                <Box>
                  <img
                    style={{
                      width: "34px",
                      height: "34px",
                      marginRight: "20px",
                    }}
                    src={anities}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>
                    Include Amenities
                  </Typography>
                </Box>
              </Button>

              {/* Buttons */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-start" },
                  gap: 2,
                }}>
                <Button
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    fontWeight: "600",
                    color: "#475569",
                    bgcolor: "#4755691A",
                    border: "1px solid #0000001A",
                    borderRadius: "10px",
                    padding: { xs: "5px 10px", md: "5px 10px" },
                    mr: 2,
                  }}>
                  Back
                </Button>
                <Button
                 onClick={handleSubmit(onSubmit)}
                  type="submit"
                  sx={{
                    fontSize: { xs: "18px", md: "18px" },
                    fontWeight: "600",
                    color: "#FFFFFF",
                    bgcolor: "#C42A25",
                    borderRadius: "10px",
                    padding: { xs: "5px 10px", md: "10px 10px" },
                  }}>
                  Save & Continue
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
  </form>
    </Box>
    </>
  );
}

export default RoomDetails;
