import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import logo from "../../Assests/logo.png";
import loginImg from "../../Assests/login.png";

function RegistrationAndLogin() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          bgcolor: "#F1F1F1",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "50px",
            flex: "1",
            margin: "30px 0",
          }}>
          <Box>
            <img src={logo} width="190.63px" alt="logo" />
          </Box>
          <Box
            sx={{
              padding: "20px",
              backgroundColor: "white",
              borderRadius: "10px",
              maxWidth: "631px",
              width: "80%",
            }}>
            <Typography
              sx={{
                fontSize: "26px",
                fontWeight: "600",
                textTransform: "capitalize",
                color: "#383737",
                textAlign: "center",
                marginBottom: "15px",
              }}>
              Welcome to the Partner Portal
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "capitalize",
                color: "#6B7280",
                textAlign: "center",
                marginBottom: "20px",
              }}>
              Choose an option below to access your owner dashboard or register
              your property. Manage bookings, update details, and start reaching
              guests today!
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                justifyContent: { xs: "center", sm: "center" }, // Stack buttons on small screens
                flexDirection: { xs: "column", sm: "row" }, // Change direction for smaller screens
                width: "100%",
                marginTop: { xs: "30px", sm: "50px" },
                flexDirection: "column",
              }}>
              {/* Use Link for Navigation */}
              <Link to="/Login" style={{ textDecoration: "none" }}>
                <Button
                  sx={{
                    color: "white",
                    bgcolor: "#C42A25",
                    marginBottom: { xs: "15px", sm: "0" }, // Add margin for small screens\
                    textAlign: "center",
                    borderRadius: "80px",
                    padding: { xs: "10px 30px", sm: "10px 50px" }, // Adjust padding for small screens
                    marginTop: "10px",
                    width: "200px",
                  }}>
                  Login
                </Button>
              </Link>
              <Link to="/register" style={{ textDecoration: "none" }}>
                <Button
                  sx={{
                    color: "#4B5563",
                    textAlign: "center",
                    bgcolor: "#E2E8F0",
                    borderRadius: "80px",
                    padding: "10px 80px",
                    padding: { xs: "10px 30px", sm: "10px 50px" }, // Adjust padding for small screens
                    marginTop: "10px",

                    width: "200px",
                  }}>
                  Registration
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>

        {/* Right Section */}
        <Box
          sx={{
            maxWidth: "35.3%",
            display: { xs: "none", sm: "flex" }, // Hide on xs (mobile) and show from sm (650px) and up
            justifyContent: "end",
            height: "100vh", // Ensure the box takes the full height of the viewport
          }}>
          <img
            style={{
              width: "90%",
              height: "100vh", // Set the image height to 100% of the viewport height
              objectFit: "cover", // Maintain the aspect ratio and cover the area
            }}
            src={loginImg}
            alt="Login"
          />
        </Box>
      </Box>

      {/* Routes */}
    </>
  );
}

export default RegistrationAndLogin;
