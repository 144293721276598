import React, { useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import DashTabs from './DashTabs';



import {
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Area,
  ComposedChart,
  Legend,
} from 'recharts';

// Data for the chart
const data = [
  { name: 'Jan', bookings: 30, revenue: 50, occupancy: 10 },
  { name: 'Feb', bookings: 80, revenue: 90, occupancy: 20 },
  { name: 'Mar', bookings: 40, revenue: 60, occupancy: 15 },
  { name: 'Apr', bookings: 60, revenue: 80, occupancy: 25 },
  { name: 'May', bookings: 70, revenue: 100, occupancy: 18 },
  { name: 'Jun', bookings: 50, revenue: 70, occupancy: 17 },
  { name: 'Jul', bookings: 65, revenue: 85, occupancy: 22 },
  { name: 'Aug', bookings: 75, revenue: 95, occupancy: 30 },
  { name: 'Sep', bookings: 90, revenue: 120, occupancy: 28 },
];

const Chart = () => {
  const [opacity, setOpacity] = useState({
    bookings: 1, // Full opacity
    revenue: 1,
    occupancy: 1,
  });

  const handleLegendClick = (e) => {
    const isAnyDimmed = Object.values(opacity).some((value) => value === 0.3);
    const isCurrentFull = opacity[e.dataKey] === 1;

    // If something is dimmed, restore all to full opacity, otherwise dim the others
    const newOpacity = isAnyDimmed
      ? { bookings: 1, revenue: 1, occupancy: 1 } // Show all at full opacity
      : {
          bookings: 0.3,
          revenue: 0.3,
          occupancy: 0.3,
          [e.dataKey]: isCurrentFull ? 1 : 0.3, // Highlight clicked or restore
        };

    setOpacity(newOpacity);
  };

  return (
    <Box sx={{ padding: '40px', backgroundColor: '#ffffff', width: '100%' }}>
       <Box sx={{
            marginBottom:"50px"
       }}>
            <DashTabs />
       </Box>
      {/* Heading Section */}
      <Typography variant="p" align="left" sx={{  fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop:"50px" }}>
        Bookings Overview
      </Typography>

      {/* Stats Section */}
      <Grid container spacing={4} sx={{ marginTop: '40px', marginBottom: '70px' }}>
        <Grid item xs={4}>
          <Box textAlign="center">
            <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: '14px', color: '#000' }}>51</Typography>
            <Typography variant="subtitle1" sx={{ color: '#888' }}>Number Of Total Bookings</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box textAlign="center">
            <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: '14px', color: '#000' }}>26%</Typography>
            <Typography variant="subtitle1" sx={{ color: '#888' }}>Total Revenue</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box textAlign="center">
            <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: '14px', color: '#000' }}>₹228k</Typography>
            <Typography variant="subtitle1" sx={{ color: '#888' }}>Total Occupancy Rate</Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Combined Bar, Line, and Area in one Composed Chart */}
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart data={data}>
          <defs>
            <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ffca28" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#ffca28" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="#888" />
          <YAxis stroke="#888" />
          <Tooltip />
          <Legend onClick={handleLegendClick} />

          {/* Bars and Lines with Conditional Opacity */}
          <Bar dataKey="bookings" fill="#e57373" name="Number of total bookings" opacity={opacity.bookings} />
          <Bar dataKey="occupancy" fill="#f06292" name="Occupancy rate" opacity={opacity.occupancy} />
          <Area
            type="monotone"
            dataKey="revenue"
            stroke="#ffca28"
            fillOpacity={1}
            fill="url(#colorRevenue)"
            name="Revenue"
            opacity={opacity.revenue}
          />
          <Line
            type="monotone"
            dataKey="revenue"
            stroke="#ffca28"
            strokeWidth={2}
            dot={{ stroke: '#ffca28', strokeWidth: 2 }}
            name="Revenue"
            opacity={opacity.revenue}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default Chart;
