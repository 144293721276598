import Sidebar from "../Sidebar/SideBar";
import Navbar from "../Navbar/Navbar";
import "./home.scss";
import { Outlet } from "react-router-dom"; // Import Outlet to render the child routes

const Home = () => {
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          {/* This is where the nested components will be rendered */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Home;
