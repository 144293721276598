import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dash1 from '../../Assests/Dash1.png'; // Import image 1
import Dash2 from '../../Assests/Dash2.png'; // Import image 2
import Dash3 from '../../Assests/Dash3.png'; // Import image 3

const DashTabs = ({ title, value, description, trendPercentage, trendPositive, image }) => {
  return (
    <Box
      sx={{
        padding: { xs: '12px', sm: '16px' },
        borderRadius: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {/* Left Section with Icon */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            width: 50,
            height: 50,
            backgroundColor: '#f5f5f5',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '10px',
          }}
        >
          <img src={image} alt="Icon" style={{ width: '30px', height: '30px' }} />
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ color: '#888', fontWeight: 'bold', fontSize: { xs: '12px', sm: '14px' }, paddingBottom: '5px' }}>
            {title}
          </Typography>
          <Typography variant="h5" sx={{ color: '#333', fontWeight: 'bold', fontSize: { xs: '18px', sm: '20px' }, paddingBottom: '5px' }}>
            {value}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: '#888', fontSize: { xs: '12px', sm: '14px' } }}>
            {description}
          </Typography>
        </Box>
      </Box>

      {/* Right Section for Trend */}
      <Box sx={{ display: 'flex', alignItems: 'center', color: trendPositive ? '#4caf50' : '#f44336' }}>
        {trendPositive ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
          {trendPercentage}%
        </Typography>
      </Box>
    </Box>
  );
};

const StatsOverview = () => {
  return (
    <Grid container spacing={3}>
      {/* First Card: New Bookings */}
      <Grid item xs={12} sm={6} md={4}>
        <DashTabs
          title="NEW BOOKINGS"
          value="22"
          description="New Booking Requests"
          image={Dash1} // Image for the first card
          trendPercentage="3.58"
          trendPositive={true}
        />
      </Grid>

      {/* Second Card: Revenue */}
      <Grid item xs={12} sm={6} md={4}>
        <DashTabs
          title="REVENUE"
          value="₹299"
          description="Total Revenue Yesterday"
          image={Dash2} // Image for the second card
          trendPercentage="5.02"
          trendPositive={false}
        />
      </Grid>

      {/* Third Card: Occupancy Rate */}
      <Grid item xs={12} sm={6} md={4}>
        <DashTabs
          title="OCCUPANCY RATE"
          value="40%"
          description="Current Occupancy Rate"
          image={Dash3} // Image for the third card
          trendPercentage="10.35"
          trendPositive={false}
        />
      </Grid>
    </Grid>
  );
};

export default StatsOverview;
